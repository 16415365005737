/**
 * Do not edit directly
 * Generated by StyleDictionary
 * Prism Design Tokens version: 2.0.0.121
 */


@import url("_common.css");
[prism-icon="stop"],
[prism-icon-family="md"] [prism-icon="stop"],
[prism-icon-family="md"][prism-icon="stop"] {
    --mask-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M6%206h12v12H6V6z%22%2F%3E%3C%2Fsvg%3E");
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
}
