@use '@angular/material' as mat;

/**
 * This file sets up the Material typography styles using Prism tokens. It is used as a temporary interop step for apps that
 * are ready to move to the DIN font. Once all apps are on the new font, this should be copied into the regular "typography.scss"
 * file and removed.
 */

$typography-headline-1: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step7),
	$font-family: var(--prism-typography-global-font-family-heading),
	$font-weight: var(--prism-typography-global-font-weight-bold),
	$line-height: var(--prism-typography-global-line-height-smaller),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30)
);

$typography-headline-2: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step6),
	$font-family: var(--prism-typography-global-font-family-heading),
	$font-weight: var(--prism-typography-global-font-weight-bold),
	$line-height: var(--prism-typography-global-line-height-small),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30)
);

$typography-headline-3: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step5),
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-regular),
	$line-height: var(--prism-typography-global-line-height-regular),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30)
);

$typography-headline-4: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step4),
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-bold),
	$line-height: var(--prism-typography-global-line-height-regular),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-30)
);

$typography-headline-5: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step3),
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-bold),
	$line-height: var(--prism-typography-global-line-height-regular),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-20)
);

$typography-headline-6: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step2),
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-bold),
	$line-height: var(--prism-typography-global-line-height-regular),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40)
);

$typography-subtitle-1: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step1),
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-regular),
	$line-height: var(--prism-typography-global-line-height-regular),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-20)
);

$typography-subtitle-2: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step0),
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-bold),
	$line-height: var(--prism-typography-global-line-height-regular),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40)
);

$typography-body: mat.define-typography-level(
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-regular),
	$font-size: var(--prism-typography-global-type-scale-step0),
	$line-height: var(--prism-typography-global-line-height-large),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40)
);

$typography-button: mat.define-typography-level(
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-bold),
	$font-size: var(--prism-typography-global-type-scale-step1),
	$line-height: var(--prism-typography-global-line-height-large),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-100)
);

$typography-caption: mat.define-typography-level(
	$font-size: var(--prism-typography-global-type-scale-step-1),
	$font-family: var(--prism-typography-global-font-family-default),
	$font-weight: var(--prism-typography-global-font-weight-regular),
	$line-height: var(--prism-typography-global-line-height-regular),
	$letter-spacing: var(--prism-typography-global-letter-spacing-spacing-40)
);

$typography-config: mat.define-typography-config(
	$headline-1: $typography-headline-1,
	$headline-2: $typography-headline-2,
	$headline-3: $typography-headline-3,
	$headline-4: $typography-headline-4,
	$headline-5: $typography-headline-5,
	$headline-6: $typography-headline-6,
	$subtitle-1: $typography-subtitle-1,
	$subtitle-2: $typography-subtitle-2,
	$body-1: $typography-body,
	$body-2: $typography-body,
	$caption: $typography-caption,
	$button: $typography-button
);
